import React from 'react';
import { Typography } from '@houseful/typography';

export const CopyrightDisclaimer: React.FC = () => (
  <Typography size="xs">
    OJOHome Canada Ltd. (“Houseful”) is a wholly-owned subsidiary of Royal Bank
    of Canada. ® / ™ Trademark(s) of Royal Bank of Canada. Used under licence.
    <br />
    <br />
    Copyright of Royal Bank of Canada © 2024
  </Typography>
);
